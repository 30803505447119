import store from "@/state/store.js";
import appConfig from "@/app.config";
import _ from "lodash";
export default {
  page: {
    title: "Wallet history",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 50,
      total: 0,
      search: "",
      text_loading: false
    };
  },
  mounted() {
    this.fetchWalletHistory(this.page);
  },
  methods: {
    handleCurrentChange(val) {
      this.fetchWalletHistory(val);
    },
    fetchWalletHistory(page) {
      this.loading = true;
      store.dispatch("admin/walletHistoryFr/getWalletHistoryFr", {
        page: page
      }).then(res => {
        this.tableData = res.data;
        this.total = res.total;
      }).finally(() => this.loading = false);
    },
    searchTable() {
      this.loading = true;
      this.text_loading = true;
      store.dispatch("admin/walletHistoryFr/searchWalletHistory", {
        search: this.search
      }).then(res => {
        this.tableData = res.data.data;
        this.total = res.total;
      }).finally(() => {
        this.loading = false;
        this.text_loading = false;
      });
    },
    debounceSearch: _.debounce(function () {
      if (this.search.length < 3) return;
      this.searchTable();
    }, 2000)
  },
  filters: {
    currency(row, key) {
      if (!row) return "";
      if (row.payment_method == "Paydunya") {
        // return amount formatted into CFA
        return row[key].toLocaleString("fr-FR", {
          style: "currency",
          currency: "CFA"
        });
      }
      return row[key].toLocaleString("en-US", {
        style: "currency",
        currency: "GHS"
      });
    }
  }
};